<template>
  <v-form ref="RegForm">
    <v-card flat>
      <v-card-text class="pb-0"
        ><span class="title titleColor--text">E-Mail Address</span>
        <v-text-field
          outlined
          dense
          clearable
          color="achtgreen"
          :hide-details="openRuleMessage"
          v-model="username"
          :rules="usernameRule"
        ></v-text-field
      ></v-card-text>
          <v-card-text class="pb-0"
        ><span class="title titleColor--text">Phone</span>
        <v-text-field
          outlined
          dense
          clearable
          color="achtgreen"
          :hide-details="openRuleMessage"
          v-model="telefon"
          :rules="telefonRule"
        ></v-text-field
      ></v-card-text>
      <v-card-text class="pb-0"
        ><span class="title titleColor--text">Vorname</span>
        <v-text-field
          outlined
          dense
          clearable
          color="achtgreen"
          :hide-details="openRuleMessage"
          v-model="vorname"
          :rules="vornameRule"
        ></v-text-field
      ></v-card-text>
      <v-card-text class="pb-0"
        ><span class="title titleColor--text">Nachname</span>
        <v-text-field
          outlined
          dense
          clearable
          color="achtgreen"
          :hide-details="openRuleMessage"
          v-model="nachname"
          :rules="nachnameRule"
        ></v-text-field
      ></v-card-text>
      <v-card-text class="pb-0"
        ><span class="title titleColor--text">Password</span>
        <v-text-field
          outlined
          dense
          clearable
          color="achtgreen"
          :hide-details="openRuleMessage"
          type="password"
          v-model="password"
          :rules="passwordRule"
        ></v-text-field
      ></v-card-text>
      <v-card-text
        ><span class="title titleColor--text">Password bestätigen</span>
        <v-text-field
          outlined
          dense
          clearable
          color="achtgreen"
          :hide-details="openRuleMessage"
          type="password"
          v-model="repassword"
          :rules="repasswordRule"
        ></v-text-field
      ></v-card-text>
      <v-card-actions class="px-4 pb-4">
        <v-btn
          color="achtgreen white--text fontFamlily text-capitalize btnWidth"
          @click="Register"
        >
          Registrieren
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
export default {
  data() {
    return {
      openRuleMessage: true,
      username: "",
      usernameRule: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      password: "",
      passwordRule: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 6 || "Password must be more than 6 characters",
      ],
      repassword: "",

      repasswordRule: [
        (v) => !!v || "Confirm password is required",
        (v) =>
          v.length >= 6 || "Confirm password must be more than 6 characters",
        (v) =>
          v == this.password ||
          "Password and the confirmation password must match.",
      ],
      vorname: "",
      vornameRule: [(v) => !!v || "Firstname is reuired"],
      nachname: "",
      nachnameRule: [(v) => !!v || "Lastname is required"],
      telefon: "",
      telefonRule: [(v) => !!v || "Phone number is required"],
    };
  },
  methods: {
    Register() {
      let checkForm = this.$refs.RegForm.validate();
      if (!checkForm) {
        this.openRuleMessage = false;
        setTimeout(() => {
          this.openRuleMessage = true;
        }, 5000);
        return;
      }
      this.$axios
        .post("/api2/reguser", {
          username: this.username,
          password: this.password,
          vorname: this.vorname,
          nachname: this.nachname,
          telefon:this.telefon
        })
        .then((res) => {
          if (res.data.status !== 0) {
            if (res.data.message == "Username Occupied") {
              this.$store.dispatch(
                "ActivateSnack",
                "Registration failed! Your Account Already Exists!"
              );
            } else if (res.data.message == "Reg Faild") {
              this.$store.dispatch("ActivateSnack", "Registration failed!");
            } else {
              this.$store.dispatch("ActivateSnack", "Registration failed!");
            }
            throw new Error("err");
          }

          this.$store.dispatch(
            "ActivateSnack",
            "Registration successd!Please Check Your Email and Activate Your Account!"
          );

          this.$store.commit("InActivateLoginDialog");
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.title {
  font-family: "Rubik" !important;
  font-size: 14px !important;
}
.btnWidth {
  width: 100%;
}
</style>