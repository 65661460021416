<template>
  <div style="padding-top: 100px">
    <v-row v-if="onloading" class="d-flex justify-space-even px-4">
      <v-col
        class="d-flex justify-center px-4"
        cols="12"
        sm="6"
        md="4"
        v-for="item in 6"
        :key="item"
      >
        <v-card
          width="310"
          max-height="400"
          hover
          link
          :to="'/room/' + item.OrtIDX"
        >
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-space-even px-4">
      <v-col
        class="d-flex justify-center px-4"
        cols="12"
        sm="6"
        md="4"
        v-for="item in Pictures"
        :key="item.OrtIDX"
      >
        <v-card
          max-width="310"
          max-height="400"
          hover
          link
          :to="'/room/' + item.OrtIDX"
        >
          <v-img :src="item.Picture[0].picture" height="250px"></v-img>
          <v-card-title class="text-break">
            {{ item.Title }}
          </v-card-title>
          <v-card-actions>
            <v-btn color="achtgreen" text class="text-center mx-auto">
              Details Anzeigen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
export default {
  data: () => ({
    show: false,
    src: require("../../../assets/picture/Raum1/1.jpeg"),
    Pictures: [],
    onloading: false,
  }),
  mounted() {
    this.autoLogin();
    this.initPicture();
  },
  methods: {
    async initPicture() {
      try {
        this.onloading = true;
        await this.$axios.get("/api2/initPictureHome").then((res) => {
          if (res.data.status !== 0) {
            //this.$router.push("/maintance");
          }
          let temp = res.data.data;
          temp = temp
            .map((item) => {
              item.Picture = JSON.parse(item.Picture);
              return item;
            })
            .filter((item) => item.Picture.length != 0);
          this.Pictures = temp;
        });
      } catch (err) {
        console.log(err);
        this.$router.push("/maintance");
      } finally {
        this.onloading = false;
      }
    },
    autoLogin() {
      // 获取URL查询参数
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      const token = urlParams.get("token");
      if (token) {
        this.$axios
          .post("/api2/tokenVerify", { token })
          .then((res) => {
            const validToken = res.data.token;
            if (validToken) {
              const twoHours = new Date(new Date().getTime() + 120 * 60 * 1000);
              Cookies.set("token", validToken, { expires: twoHours });
              let decoded = jwt_decode(validToken);
              Cookies.set("decoded", JSON.stringify(decoded), {
                expires: twoHours,
              });
              this.$store.dispatch("CheckCookieShowUserName");
            }
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style scoped>
.titleWordWrap {
  white-space: normal;
}
.text-break {
  font-size: 16px;
}
</style>
