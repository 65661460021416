<template>
  <v-form ref="PurchaseForm">
    <v-card class="mx-2">
      <v-card-title>Benutzerinformationen</v-card-title>
      <v-row class="px-4">
        <v-col cols="12" xs="12" sm="6"
          ><v-text-field
            v-model="UserinfoSubmit.Firstname"
            label="Vorname"
            :rules="vornameRule"
          ></v-text-field
        ></v-col>
        <v-col cols="12" xs="12" sm="6"
          ><v-text-field
            v-model="UserinfoSubmit.Lastname"
            label="Nachname"
            :rules="NachnameRule"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row class="px-4">
        <v-col cols="12" xs="12" sm="6"
          ><v-text-field
            v-model="UserinfoSubmit.Email"
            label="E-Mail-Adresse"
            :rules="usernameRule"
          ></v-text-field
        ></v-col>
        <v-col cols="12" xs="12" sm="6"
          ><v-text-field
            v-model="UserinfoSubmit.Telephone"
            label="Telefon"
            :rules="TelephoneRule"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row class="px-4">
        <v-col cols="12" xs="12" sm="6">
          <v-card-text
            >Bitte geben Sie uns einige Informationen über Ihren
            Verwendungszweck, damit wir die entsprechende Ausrüstung im Voraus
            für Sie vorbereiten können. Zum Beispiel, wenn Sie und Ihre Freunde
            insgesamt fünf Personen sind, haben wir genügend Plätze für
            Sie.</v-card-text
          ></v-col
        >
        <v-col cols="12" xs="12" sm="6">
          <v-textarea
            autocomplete="email"
            label="Beschreibung"
            dense
            v-model="UserinfoSubmit.Note"
            :rules="DescriptionRule"
            auto-grow
          ></v-textarea
        ></v-col>
      </v-row>
    </v-card>
  </v-form>
</template>
<script>
export default {
  data() {
    return {
      UserinfoSubmit: {
        Firstname: "",
        Lastname: "",
        Email: "",
        Telephone: "",
        Note: "",
      },
      vornameRule: [(v) => !!v || "Firstname is reuired"],
      NachnameRule: [(v) => !!v || "Lastname is reuired"],
      usernameRule: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      TelephoneRule: [(v) => !!v || "Telephone is reuired"],
      DescriptionRule: [(v) => !!v || "Description is reuired"],
    };
  },
  watch: {
    UserinfoSubmit: {
      deep: true,
      handler(newVal) {
        this.$emit("callbackUserInfo", newVal);
        this.CheckForm();
      },
    },
  },
  methods: {
    CheckForm() {
      let checkForm = this.$refs.PurchaseForm.validate();
      this.$store.commit("PurchaseCheckCommit", checkForm);
    },
  },
};
</script>
