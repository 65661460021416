<template>
  <v-card>
    <v-data-table
      :headers="Headers"
      :items="Events"
      :search="search"
      item-key="prIDX"
      class="elevation-1 text-center"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.StatusFormat`]="{  }">
        <v-chip color="grey" dark>
          Vollständig
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      search: "",
      Headers: [
        {
          text: "Name des Zimmers",
          align: "start",
          sortable: false,
          value: "Title",
        },
        { text: "Datum", value: "Date" },
        { text: "Zeitspanne", value: "Period" },
        // { text: "Dauer(Stunde)", value: "DurationFormat" },
        { text: "Betrag(€)", value: "Paid_price" },
        { text: "Status", value: "StatusFormat" },
      ],
      Events: [],
      StatusCheck: { 0: "Ready", 1: "Review", 2: "Refunded" },
    };
  },
  mounted() {
    this.initDataTable();
  },
  methods: {
    initDataTable() {
      this.$axios
        .get("/my2/initUserEvents")
        .then((res) => {
          if (res.data.status !== 0) {
            this.$store.dispatch(
              "ActivateSnack",
              "Table Initialization Failed!"
            );
          }
          let temp = res.data.events;
          temp = temp.map((item) => {
            item.Date = dayjs(item.Book).add(0, "minute").format("YYYY-MM-DD");
            let startString = dayjs(item.Book).format("HH:mm");
            let endString = dayjs(item.Book)
              .add(item.Duration, "minute")
              .format("HH:mm");
            item.Period = `${startString} - ${endString}`;
            item.StatusFormat = this.StatusCheck[item.Refund];

            item.DurationFormat = item.Duration / 60;
            return item;
          });
          temp = temp.sort(
            (a, b) => dayjs(b.Book).valueOf() - dayjs(a.Book).valueOf()
          );
          temp = temp.filter(
            (item) => dayjs(item.Book).valueOf() - dayjs().valueOf() < 0
          );
          this.Events = temp;
        })
        .catch(() => {});
    },
    getColor(Status) {
      if (Status == 0) return "#92d050";
      else if (Status == 1) return "orange";
      else return "red";
    },
  },
};
</script>
