<template>
  <v-card class="px-4 mt-9">
    <v-row v-for="(classitem, i) in Equipment" :key="i">
      <v-col cols="12" class="titleFont"
        ><v-card-title>{{ classitem.Title }}:</v-card-title></v-col
      >
      <v-col
        cols="12"
        sm="4"
        md="2"
        v-for="item in classitem.equipments"
        :key="item.prIDX"
        class="text-center pt-0"
      >
        <v-card-text
          :class="[
            'pb-0',
            'textFont',
            item.check == 0 ? 'fontInActive--text' : 'fontActive--text',
            'pt-0',
          ]"
          ><v-icon v-if="item.check == 1" class="achtgreen--text"
            >mdi-check-circle-outline</v-icon
          >
          <v-icon v-else class="fontInActive--text"
            >mdi-check-circle-outline</v-icon
          >
        </v-card-text>
        <span class="pl-1" style="overflow-wrap: normal;">{{ item.Name }}</span>
      </v-col>
    </v-row>
    <v-row v-if="!!EquipAllowPerson">
      <v-col cols="12" class="titleFont pt-0"
        ><v-card-title class="pt-0"
          >Erlaubte Personenzahl (max.): {{ EquipAllowPerson }}</v-card-title
        ></v-col
      >
    </v-row>
    <v-row v-if="!!EquipArea">
      <v-col cols="12" class="titleFont pt-0 pb-0"
        ><v-card-title class="pt-0"
          >Größe des Raumes: {{ EquipArea }} m²</v-card-title
        ></v-col
      >
      <v-col cols="12" class="pt-0">
        <v-card-text class="textFont fontInActive--text pt-0">
          Die Größenangaben sind ungefähre Maße. Diese sind unverbindlich und
          freiwillig.</v-card-text
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["EquipAllowPerson", "EquipArea", "Equipment"]),
  },
};
</script>
