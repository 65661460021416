<template>
  <v-card>
    <v-row class="fill-height pa-4">
      <v-col class="overflow-auto">
        <v-sheet height="64" width="900">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Today
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600" width="900">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="achtgreen"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" max-width="350px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-toolbar-title
                  v-html="selectedEvent.name"
                  class="fontFamlily"
                ></v-toolbar-title>
              </v-toolbar>
              <div class="d-flex">
                <v-card-text class="fontFamlily text-center">
                  <div class="pr-1">Datum:</div>
                  <div>{{ selectedEvent.date }}</div>
                </v-card-text>

                <v-card-text class="fontFamlily text-center">
                  <div class="pr-1">Startzeit:</div>
                  <div>{{ selectedEvent.startString }}</div>
                </v-card-text>
                <v-card-text class="fontFamlily text-center">
                  <div class="pr-1">Endzeit:</div>
                  <div>{{ selectedEvent.endString }}</div>
                </v-card-text>
              </div>

              <v-card-actions class="d-flex justify-end">
                <v-btn
                  text
                  class="text-capitalize fontFamlily grey lighten-1"
                  @click="selectedOpen = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import dayjs from "dayjs";
export default {
  data: () => ({
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
  }),
  created() {
    this.initUserEvents();
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    initUserEvents() {
      this.$axios
        .get("/my2/initUserEvents")
        .then((res) => {
          if (res.data.status !== 0) {
            this.$store.dispatch(
              "ActivateSnack",
              "Calendar Initialization Failed!"
            );
          }
          this.setEventsMounted(res.data.events, res.data.ServerTime);
        })
        .catch(() => {});
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        event.date = dayjs(event.start).add(0, "minute").format("YYYY-MM-DD");
        event.startString = dayjs(event.start).add(0, "minute").format("HH:mm");
        event.endString = dayjs(event.end).add(0, "minute").format("HH:mm");
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    setEventsMounted(data, ServerTime) {
      const events = [];
      data.map((item) => {
        item.FinishTime = dayjs(item.Book)
          .add(item.Duration, "minutes")
          .valueOf();
        let color;
        if (item.FinishTime < ServerTime) {
          color = "#616161";
        } else {
          color = "#92d050";
        }
        events.push({
          name: item.Title,
          start: dayjs(item.Book).valueOf(),
          end: item.FinishTime,
          color: color,
          timed: true,
        });
        return item;
      });

      this.events = events;
    },
    updateRange() {
      //updateRange({ start, end }) {
      // const events = [];
      // const min = dayjs(`${start.date}T00:00:00`).valueOf();
      // const max = dayjs(`${end.date}T23:59:59`).valueOf();
      // this.initEvents.map((item) => {
      //   if (
      //     dayjs(item.Book).valueOf() >= min &&
      //     dayjs(item.Book).valueOf() <= max
      //   ) {
      //     item.FinishTime = dayjs(item.Book)
      //       .add(item.Duration, "minutes")
      //       .valueOf();
      //     events.push({
      //       name: item.Title,
      //       start: dayjs(item.Book).valueOf(),
      //       end: item.FinishTime,
      //       color: "#92d050",
      //       timed: true,
      //     });
      //     return item;
      //   }
      // });
      // this.events = events;
    },
  },
};
</script>
