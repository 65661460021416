<template>
  <v-card class="mx-2">
    <v-card-title
      >Informationen zur Buchung
      <span class="fontInActive--text text-subtitle-2 pl-2"
        >(*Hinweis:Bitte beachten Sie dass Buchungszeit inkl. Vorbereitungszeit
        ist.)</span
      ></v-card-title
    >
    <v-row class="px-4">
      <v-col cols="12" xs="12" sm="6"
        ><v-text-field
          :value="BookingInfo.RoomName"
          label="Raumname"
          disabled
          style="font-size: 12px"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row class="px-4">
      <v-col cols="12" xs="12" sm="6"
        ><v-text-field
          :value="BookingInfo.Date"
          label="Datum"
          disabled
        ></v-text-field
      ></v-col>
      <v-col cols="12" xs="12" sm="6"
        ><v-text-field
          :value="BookingInfo.TimeStamp"
          label="Zeitabschnitt"
          disabled
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row class="px-4">
      <v-col cols="12" xs="12" sm="6"
        ><v-text-field
          :value="BookingInfo.Hours + 'h'"
          label="Dauer"
          disabled
        ></v-text-field
      ></v-col>
      <v-col cols="12" xs="12" sm="6"
        ><v-text-field
          :value="BookingInfo.Price + '€'"
          label="Gesamtpreis"
          disabled
        ></v-text-field
      ></v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    BookingInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
