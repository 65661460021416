var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{staticClass:"white",attrs:{"app":"","height":"100","elevation":"4"}},[_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('v-toolbar-title',{staticClass:"px-sm-4 px-md-4"},[_c('v-img',{attrs:{"width":"170","height":"100%","src":require("../../assets/logo/LOGO_dunkel_1.svg")}})],1),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},[_vm._l((_vm.header),function(item,index){return _c('a',{key:index,staticClass:"d-flex align-center px-sm-2 px-md-2 text-decoration-none",attrs:{"href":item.link}},[(_vm.CeckRouterPath || item.title == 'Raumvermietung')?_c('v-btn',{staticClass:"px-2 text-h6 text-capitalize",attrs:{"color":"#37b038","text":"","plain":""}},[_vm._v(_vm._s(item.title))]):_vm._e()],1)}),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"text-caption text-capitalize text-center",attrs:{"color":"#37b038","text":"","plain":""},on:{"click":function($event){_vm.LoginedStatus ? _vm.GoHomePage() : _vm.openLoginDialogorGoUserBackend()}}},[_c('v-icon',[_vm._v("mdi-account-outline")]),(_vm.LoginedStatus)?_c('span',{class:_vm.LoginedStatus ? 'userNavColor--text' : ''},[_vm._v(_vm._s(_vm.LoginedUserName))]):_c('span',[_c('div',[_vm._v("Kunden für Raumvermietung")]),_c('div',[_vm._v("Meine Acht Töne")])])],1)],1)],2),_c('v-toolbar-items',{staticClass:"hidden-md-and-up"},[_c('v-app-bar-nav-icon',{attrs:{"variant":"text"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}})],1)],1)],1),_c('v-navigation-drawer',{attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{style:({
          position: 'fixed',
          top: _vm.scrollTop + 'px',
          width: '100%',
          zIndex: '1000',
        })},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c('v-list-item',[_c('v-btn',{staticClass:"text-caption text-capitalize text-center",attrs:{"color":"#37b038","text":"","plain":""},on:{"click":function($event){_vm.LoginedStatus
                    ? _vm.GoHomePage()
                    : _vm.openLoginDialogorGoUserBackend()}}},[_c('v-icon',[_vm._v("mdi-account-outline")]),(_vm.LoginedStatus)?_c('span',{class:_vm.LoginedStatus ? 'userNavColor--text' : ''},[_vm._v(_vm._s(_vm.LoginedUserName))]):_c('span',[_c('div',[_vm._v("Kunden für Raumvermietung")]),_c('div',[_vm._v("Meine Acht Töne")])])],1)],1),_c('v-divider'),_vm._l((_vm.header),function(item,index){return _c('v-list-item',{key:index,staticClass:"px-8"},[_c('v-list-item-title',{staticClass:"achtgreen--text",on:{"click":function($event){return _vm.redirectToWebsite(item.link)}}},[_vm._v(_vm._s(item.title))])],1)})],2)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }