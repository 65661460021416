<template>
  <v-card
    v-if="UserBanner"
    height="200"
    elevation="0"
    color="achtdeepgreen"
    class="rounded-0 d-flex justify-end align-end"
  >
    <v-btn color="white" text link to='/' class="white--text text-capitalize pr-9">
      <v-icon class="white--text">mdi-home</v-icon><span class="text-caption">Startseite</span> </v-btn
    >
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["UserBanner"]),
  },
};
</script>

