<template>
  <v-card class="px-4 mt-9">
    <v-row>
      <v-col cols="12" class="titleFont"
        ><v-card-title class="font-weight-bold"
          >Verfügbarkeitszeit</v-card-title
        ></v-col
      >
      <v-col cols="12" sm="12" :md="DateSelected ? 4 : 8" class="pl-5">
        <v-date-picker
          v-model="date"
          :allowed-dates="allowedDates"
          :min="minSelectedTime"
          :max="maxSelectedTime"
          locale="de"
          full-width
          header-color="achtgreen"
          color="red"
          elevation="3"
        ></v-date-picker>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        v-if="DateSelected"
        class="d-flex flex-column text-center"
      >
        <span
          @click="selectTimeSlots(item)"
          :class="[
            item.check == true ? 'achtgreen' : 'fontInActive',
            'white--text',
            'textFont',
            'mx-2',
            'pa-1',
            'mb-1',
          ]"
          v-for="(item, index) in availableSlots"
          :key="index"
          >{{ `${item.timeS} - ${item.timeE}` }}</span
        >
      </v-col>
      <v-col cols="12" sm="12" md="4" class="pl-5">
        <v-card elevation="0" class="pa-3 mainBg">
          <v-toolbar elevation="0" class="px-0 ma-0 mainBg" dense>
            <v-toolbar-title class="titleFont"
              ><span class="pr-5">€{{ UsedPrice }}</span
              ><span class="textFont">pro Stunde</span></v-toolbar-title
            >
          </v-toolbar>
          <v-card-text class="text-subtitle-1 text-center font-weight-bold"
            >Rabatte</v-card-text
          >
          <v-card-text
            class="rabatBg pa-1 textFont text-center"
            v-for="(item, index) in PriceAndDiscount"
            :key="index"
            >ab <b>{{ item.ab == 0 ? 1 : item.ab }}</b> Std. —
            <b>{{ item.price }}</b
            ><span class="px-1"
              >statt €{{ PriceAndDiscount[0].price }}</span
            ></v-card-text
          >
        </v-card>
        <div class="mt-6 mainBg" v-if="showCalculation">
          <v-row>
            <v-col cols="6" class="textFont text-center font-weight-bold">
              Datum
            </v-col>
            <v-col cols="6" class="textFont text-center font-weight-bold">
              Zeit
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="6" class="textFont fontActive--text text-center">
              {{ date }}
            </v-col>
            <v-col cols="6" class="textFont text-center">
              {{ ShowBookedTimeStamp.timeS }} -
              {{ ShowBookedTimeStamp.timeE }}
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="6" class="textFont text-center pb-0 pt-5">
              Stundenanzahl
            </v-col>
            <v-col cols="6" class="textFont text-center pb-0 pt-5">
              <span class="font-weight-bold">{{
                ShowBookedTimeStamp.hours
              }}</span>
              Stunden
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="textFont text-center"> Gesamtpreis </v-col>
            <v-col cols="6" class="textFont text-center">
              <span class="font-weight-bold"
                >€{{ UsedPrice * ShowBookedTimeStamp.hours }}</span
              >
            </v-col>
          </v-row>
        </div>
        <v-btn
          color="achtgreen"
          class="textFont d-flex mx-auto mt-5"
          @click="LoginedStatus ? Booking() : Login()"
          ><v-icon class="white--text">mdi-flash</v-icon
          ><span class="white--text">{{
            LoginedStatus ? "Sofort Buchen" : "Login"
          }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-card-text class="textFont fontInActive--text text-left"
        ><v-icon class="achtdeepgreen--text pr-4"
          >mdi-information-outline</v-icon
        >
        <span
          >Es werden nur verfügbare Zeitblöcke angezeigt. Beachte bitte
          Stornobestimmungen unten im Text dieser Anzeige.</span
        ></v-card-text
      ></v-row
    >
    <v-row>
      <v-card-text class="textFont fontInActive--text text-left pt-0"
        ><v-icon class="achtdeepgreen--text pr-4"
          >mdi-information-outline</v-icon
        >
        <span
          >Wenn Sie regelmässige Zeit mehr als 1 Tag kommen wollen, kontaktieren
          Sie bei uns per Mail oder Telefon.</span
        ></v-card-text
      ></v-row
    >
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
export default {
  data: () => ({
    date: null,
    DateSelected: false,
    showCalculation: false,
    availableSlots: [],
    RaumIDX: null,
    RoomName: "",
    validDates: [],
    PriceAndDiscount: [],
    UsedPrice: null,
    minSelectedTime: null,
    maxSelectedTime: null,
    selectedTimeperiod: { timeS: 0, timeE: 0 },
    ShowBookedTimeStamp: { timeS: null, timeE: null, hours: 0 },
  }),
  watch: {
    date(val, old) {
      if (old) {
        this.availableSlots.map((item) => (item.check = false));
        this.showCalculation = false;
      }
      if (val) {
        let temp = this.validDates.find((item) => item.Dates == val);
        if (temp) {
          this.availableSlots = temp.Timeperiod;
        }
        this.DateSelected = true;
        this.selectedTimeperiod.timeS = 0;
        this.selectedTimeperiod.timeE = 0;
      }
    },
    selectedTimeperiod: {
      handler(val) {
        if (val.timeS == 0 && val.timeE == 0) return;
        let CheckItems = this.availableSlots.filter(
          (item) => item.check == true
        );
        CheckItems = CheckItems.sort(
          (a, b) =>
            dayjs(`2020/01/01 ${a.timeS}`).valueOf() -
            dayjs(`2020/01/01 ${b.timeS}`).valueOf()
        );
        this.ShowBookedTimeStamp.timeS = dayjs(
          `2020/01/01 ${CheckItems[0].timeS}`
        ).format("HH:mm");
        this.ShowBookedTimeStamp.timeE = dayjs(
          `2020/01/01 ${CheckItems[CheckItems.length - 1].timeE}`
        ).format("HH:mm");
        this.ShowBookedTimeStamp.hours = CheckItems.length;
        //calculate price
        let price_temp;
        for (let i = 0; i < this.PriceAndDiscount.length; i++) {
          const element = this.PriceAndDiscount[i];
          if (element.ab <= CheckItems.length) {
            price_temp = element.price;
          }
        }
        this.UsedPrice = price_temp;
      },
      deep: true,
    },
  },
  mounted() {
    this.RaumIDX = this.$router.currentRoute.params.id;
    this.initValidDate();
  },
  computed: {
    ...mapGetters(["LoginedStatus"]),
  },
  methods: {
    Booking() {
      if (this.showCalculation == false) return;
      // get token
      this.$axios
        .get("/my2/purchasetoken", {
          params: {
            RaumIDX: this.RaumIDX,
            timeS: this.ShowBookedTimeStamp.timeS,
            Date: this.date,
            Hours: this.ShowBookedTimeStamp.hours,
            Price: this.UsedPrice * this.ShowBookedTimeStamp.hours,
          },
        })
        .then((res) => {
          if (res.data.status !== 0) {
            this.$store.dispatch(
              "ActivateSnack",
              "System Error! Try It Again Later!"
            );
          }
          let purchaseToken = res.data.purchaseToken;
          this.$router.push(`/purchase/${purchaseToken}`);
        })
        .catch(() => {});
    },
    Login() {
      this.$store.commit("ActivateLoginDialog");
    },
    initValidDate() {
      this.$axios
        .get("/api2/GetValidDateForRoom", {
          params: { RaumIDX: this.RaumIDX },
        })
        .then((res) => {
          if (res.data.status !== 0) {
            if (res.data.message == "No Room") {
              this.$router.push("/");
            } else {
              this.$router.push("/maintance");
            }
          }
          let tempDates = res.data.data;
          let disabledDates = this.manuallyDisableDates();
          this.validDates = tempDates.filter(
            (item) => !disabledDates.includes(item.Dates)
          );
          
          let ServerTime = res.data.ServerTime;
          this.minSelectedTime = dayjs(ServerTime).format("YYYY-MM-DD");
          this.maxSelectedTime = dayjs(ServerTime)
            .add(30, "days")
            .format("YYYY-MM-DD");
          this.PriceAndDiscount = JSON.parse(res.data.Price.Priceinfo);
          this.UsedPrice = this.PriceAndDiscount[0].price;
        })
        .catch(() => {});
    },
    allowedDates(val) {
      let check = this.validDates.find((item) => item.Dates == val);
      return !!check;
    },
    manuallyDisableDates() {
      const startDate = dayjs("2024-01-01");
      const endDate = dayjs("2024-12-31");
      let specialDays = ['2024-10-03','2024-12-25','2024-12-26']
      let currentDate = startDate;
      let sundays = [];

      while (
        currentDate.isBefore(endDate) ||
        currentDate.isSame(endDate, "day")
      ) {
        if (currentDate.day() === 0) {
          sundays.push(currentDate.format("YYYY-MM-DD"));
        }
        currentDate = currentDate.add(1, "day");
      }

      return [...sundays,...specialDays];
    },
    selectTimeSlots(item) {
      item.check = !item.check;
      if (item.check) {
        if (this.selectedTimeperiod.timeS == 0) {
          this.selectedTimeperiod.timeS = dayjs(
            `2020/01/01 ${item.timeS}`
          ).valueOf();
          this.showCalculation = true;
          return;
        }
        if (this.selectedTimeperiod.timeE == 0) {
          if (
            dayjs(`2020/01/01 ${item.timeS}`).valueOf() >
            this.selectedTimeperiod.timeS
          ) {
            this.selectedTimeperiod.timeE = dayjs(
              `2020/01/01 ${item.timeS}`
            ).valueOf();
          } else {
            this.selectedTimeperiod.timeE = this.selectedTimeperiod.timeS;
            this.selectedTimeperiod.timeS = dayjs(
              `2020/01/01 ${item.timeS}`
            ).valueOf();
          }
        } else if (
          dayjs(`2020/01/01 ${item.timeS}`).valueOf() <
          this.selectedTimeperiod.timeS
        ) {
          this.selectedTimeperiod.timeS = dayjs(
            `2020/01/01 ${item.timeS}`
          ).valueOf();
        } else if (
          dayjs(`2020/01/01 ${item.timeS}`).valueOf() >
          this.selectedTimeperiod.timeE
        ) {
          this.selectedTimeperiod.timeE = dayjs(
            `2020/01/01 ${item.timeS}`
          ).valueOf();
        }
        this.checkAvailabilityOfTimestamp();
      } else {
        let CheckItems = this.availableSlots.filter(
          (item) => item.check == true
        );
        if (CheckItems.length == 0) {
          this.selectedTimeperiod.timeS = 0;
          this.selectedTimeperiod.timeE = 0;
          this.showCalculation = false;
          return;
        }
        CheckItems.push(item);
        CheckItems = CheckItems.sort(
          (a, b) =>
            dayjs(`2020/01/01 ${a.timeS}`).valueOf() -
            dayjs(`2020/01/01 ${b.timeS}`).valueOf()
        );
        let earlyTime = dayjs(`2020/01/01 ${CheckItems[0].timeS}`).valueOf();
        let lateTime = dayjs(
          `2020/01/01 ${CheckItems[CheckItems.length - 1].timeS}`
        ).valueOf();
        if (dayjs(`2020/01/01 ${item.timeS}`).valueOf() == earlyTime) {
          this.selectedTimeperiod.timeS = dayjs(earlyTime)
            .add(60, "minutes")
            .valueOf();
        } else if (dayjs(`2020/01/01 ${item.timeS}`).valueOf() == lateTime) {
          this.selectedTimeperiod.timeE = dayjs(lateTime)
            .subtract(60, "minutes")
            .valueOf();
        } else {
          this.selectedTimeperiod.timeS = dayjs(
            `2020/01/01 ${item.timeS}`
          ).valueOf();
        }
        this.checkAvailabilityOfTimestamp();
      }
    },
    checkAvailabilityOfTimestamp() {
      let CheckTimes = [];
      let i = 0;
      let flag = true;
      while (flag) {
        let temp = dayjs(this.selectedTimeperiod.timeS)
          .add(60 * i, "minutes")
          .format("HH:mm:ss");
        CheckTimes.push(temp);
        i++;
        if (
          dayjs(`2020/01/01 ${temp}`).valueOf() >= this.selectedTimeperiod.timeE
        ) {
          break;
        }
      }
      //show
      let ValidTime = [];
      for (let i = 0; i < CheckTimes.length; i++) {
        let flag = false;
        for (let j = 0; j < this.availableSlots.length; j++) {
          if (CheckTimes[i] == this.availableSlots[j].timeS) {
            ValidTime.push(CheckTimes[i]);
            break;
          }
          if (j == this.availableSlots.length - 1) {
            flag = true;
          }
        }
        if (flag) {
          break;
        }
      }
      this.availableSlots.map((item) => {
        item.check = false;

        if (ValidTime.indexOf(item.timeS) > -1) {
          item.check = true;
        }
      });

      //show calculation
      this.showCalculation = true;
    },
  },
};
</script>
