<template>
  <v-card class="mx-2">
    <v-card-title>Zahlungsverfahren</v-card-title>
    <v-row class="px-4">
      <v-col cols="12" xs="12" sm="6">
        <v-card-subtitle class="text-center"
          >Unterstützte Zahlungsarten</v-card-subtitle
        >
        <v-row>
          <v-col cols="12" class="d-flex flex-row justify-center">
            <v-img
              :src="picture.picture"
              max-height="50"
              max-width="100"
              contain
              v-for="picture in LogoPicture"
              :key="picture.id"
            ></v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xs="12" sm="6"
        ><v-card-text class="text-center fontFamlily textFont font-weight-bold"
          >Gesamtpreis : €{{ BookingInfo.Price }}</v-card-text
        >
        <v-row>
          <v-col cols="12" class="d-flex flex-row justify-center">
            <!-- <v-btn color="achtgreen" class="textFont d-flex mx-auto mt-5"
              ><v-icon class="white--text">mdi-flash</v-icon
              ><span class="white--text">Buchen mit {{ select }}</span>
            </v-btn> -->
            <div ref="payContainer" v-show="UserFormChecked"></div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    BookingInfo: {
      type: Object,
      required: true,
    },
    UserInfo: {
      type: Object,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["PurchaseCheck"]),
  },
  data() {
    return {
      Userinfomation: {},
      insertID: 0,
      UserFormChecked: false,
      //select: "PayPal",
      //PaymentMethods: ["PayPal", "Sofort"],
      LogoPicture: [
        {
          id: 1,
          picture:
            "https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_150x38.png",
        },
        {
          id: 2,
          picture: require("../../../assets/logo/sofort-logo-vector.svg"),
        },
      ],
    };
  },
  watch: {
    UserInfo: {
      deep: true,
      handler(newVal) {
        this.Userinfomation = newVal;
        if (this.PurchaseCheck) {
          this.UserFormChecked = true;
        } else {
          this.UserFormChecked = false;
        }
      },
    },
  },
  mounted: function () {
    const script = document.createElement("script");
    // //live
    script.src =
      "https://www.paypal.com/sdk/js?client-id=ARhRe1MYRoIlZbg0egvDkOsb0AAwFgHoM4bwoV1zUqBN-VMr2QSFRem9rpXhWTe40ZlYKiNwbIZFmlgU&components=buttons&enable-funding=sofort&currency=EUR&disable-funding=credit,card";
    //testing
    // script.src =
    //   "https://www.paypal.com/sdk/js?client-id=Affw6Lr5jdoygBBIIUk5omEja86m8L2PF_-YplGg3aO3AHBMb27KWBCpsO2i9P3Ky5DTSpLBYSGqXk-R&components=buttons&enable-funding=sofort&currency=EUR&disable-funding=credit,card";
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    setLoaded: function () {
      window.paypal
        .Buttons({
          // Order is created on the server and the order id is returned
          createOrder: () => {
            return this.$axios
              .post("/my2/paypalorders", {
                // use the "body" param to optionally pass additional order information
                // like product ids or amount
                purchaseToken: this.token,
                Userinfomation: this.Userinfomation,
              })
              .then((res) => {
                if (res.data.status !== 0) {
                  if (res.data.message == "Conflict Exsit") {
                    this.$store.dispatch(
                      "ActivateSnack",
                      "Das ausgewählte Zeitfenster existiert bereits! Bitte wählen Sie neu!"
                    );
                    setTimeout(() => {
                      this.$router.push(`/room/${this.BookingInfo.RaumIDX}`);
                    }, 3000);
                  } else if (res.data.message == "Timeout") {
                    this.$store.dispatch(
                      "ActivateSnack",
                      "Link funktioniert nicht, bitte versuchen Sie es erneut!"
                    );
                    setTimeout(() => {
                      this.$router.push(`/room/${this.BookingInfo.RaumIDX}`);
                    }, 3000);
                  }
                  throw new Error("err");
                }
                let order = res.data.order;
                this.insertID = order.insertID;
                return order;
              })
              .then((order) => order.id)
              .catch(() => {});
          },
          onApprove: (data) => {
            return this.$axios
              .post(`/my2/paypalorders/${data.orderID}/capture`, {
                insertID: this.insertID,
              })
              .then((res) => {
                if (res.data.status !== 0) {
                  throw new Error("err");
                }
                this.$store.dispatch(
                  "ActivateSnack",
                  "Zahlung erfolgreich! Wir danken Ihnen für Ihre Unterstützung!"
                );
                setTimeout(() => {
                  this.$router.push("/userDashboard/calendars");
                }, 2000);
              })
              .catch((err) => {
                console.log(err);
              });
          },
          onCancel: () => {
            this.$axios
              .post("/my2/canclePaypalorders", {
                // use the "body" param to optionally pass additional order information
                // like product ids or amount
                insertID: this.insertID,
              })
              .then((res) => {
                if (res.data.status !== 0) {
                  throw new Error("err");
                }
              })
              .catch(() => {});
          },
          onError: (e) => {
            console.log("Err!", e);
            this.$axios
              .post("/my2/canclePaypalordersError", {
                // use the "body" param to optionally pass additional order information
                // like product ids or amount
                insertID: this.insertID,
                error:e
              })
              .then((res) => {
                if (res.data.status !== 0) {
                  throw new Error("err");
                }
              })
              .catch(() => {});
          },
        })
        .render(this.$refs.payContainer);
    },
  },
};
</script>
