import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                achtdeepgreen: '#378038',
                achtgreen: '#92d050',
                rabatBg: '#d9d9d9',
                rabatTitle: '#474747',
                mainBg: "#f6f9fc",
                fontInActive:"#c3c3c3",
                fontActive:"#3c3c3c",
                titleColor:"#373f50",
                userNavColor:"#fe696a",
                
            },
        },
    },
});
