<template>
  <div id="app">
    <v-app>
      <!-- 路由占位符 -->
      <TopNavComponent></TopNavComponent>
      <UserBanner />
      <div class="mainBg">
        <v-main class="wrapper mt-5 pb-9 pt-0">
          <router-view></router-view>
        </v-main>
      </div>
      <FooterComponent></FooterComponent>
      <LoginDialog />
      <SnackComponent />
    </v-app>
  </div>
</template>

<script>
import TopNavComponent from "@/components/ui/TopNavComponent.vue";
import FooterComponent from "@/components/ui/FooterComponent.vue";
import UserBanner from "@/components/ui/UserBanner.vue";
import LoginDialog from "@/components/Pages/LoginAndRegisterDialog/LoginDialog.vue";
import SnackComponent from "./components/ui/SnackBar.vue";

export default {
  name: "app",
  components: {
    TopNavComponent,
    FooterComponent,
    LoginDialog,
    SnackComponent,
    UserBanner,
  },
  data() {
    return {
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap");

.textFont {
  font-family: "Rubik";
  font-size: 1rem;
}
.titleFont {
  font-family: "Rubik";
  font-size: 1.8rem;
  font-weight: 700;
}
.fontFamlily {
  font-family: "Rubik" !important;
}

.wrapper {
  max-width: 1260px;
  margin: 0 auto;
  height: 100%;
  font-family: "Rubik", sans-serif;
}
</style>