<template>
  <v-row justify="center">
    <v-dialog v-model="LoginDialog" persistent max-width="500">
      <v-card class="rounded-lg">
        <v-toolbar flat>
          <v-tabs
            v-model="tab"
            align-with-title
            active-class="achtgreen--text"
            color="red"
          >
            <v-tabs-slider color="achtgreen"></v-tabs-slider>

            <v-tab
              class="mx-0 text-capitalize font-weight-medium text-subtitle-1"
            >
              <span class="fontFamlily">Anmelden</span>
            </v-tab>

            <v-tab
              class="mx-0 text-capitalize font-weight-medium text-subtitle-1"
            >
              <span class="fontFamlily">Registrieren</span>
            </v-tab>
          </v-tabs>
          <v-spacer></v-spacer>
          <v-icon @click="LoginDialogClose">mdi-close</v-icon>
        </v-toolbar>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <LoginUI />
          </v-tab-item>
          <v-tab-item>
            <RegisterVIew />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import LoginUI from "./LoginView.vue";
import { mapGetters } from "vuex";
import RegisterVIew from "./RegisterVIew.vue";
export default {
  components: { LoginUI, RegisterVIew },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    ...mapGetters(["LoginDialog"]),
  },
  methods: {
    LoginDialogClose(){
      this.$store.commit("InActivateLoginDialog")
    }
  },
};
</script>
<style scoped>
.v-tab:not(.v-tab--active) {
  color: #4b566b !important;
}
</style>