<template>
  <div>
    <v-app-bar app height="100" class="white" elevation="4">
      <v-toolbar elevation="0">
        <v-toolbar-title class="px-sm-4 px-md-4"
          ><v-img
            width="170"
            height="100%"
            src="../../assets/logo/LOGO_dunkel_1.svg"
          ></v-img
        ></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-sm-and-down">
          <a
            v-for="(item, index) in header"
            :key="index"
            :href="item.link"
            class="d-flex align-center px-sm-2 px-md-2 text-decoration-none"
          >
            <v-btn
              v-if="CeckRouterPath || item.title == 'Raumvermietung'"
              color="#37b038"
              text
              plain
              class="px-2 text-h6 text-capitalize"
              >{{ item.title }}</v-btn
            >
          </a>
          <div class="d-flex align-center">
            <v-btn
              color="#37b038"
              text
              plain
              class="text-caption text-capitalize text-center"
              @click="
                LoginedStatus ? GoHomePage() : openLoginDialogorGoUserBackend()
              "
              ><v-icon>mdi-account-outline</v-icon
              ><span
                v-if="LoginedStatus"
                :class="LoginedStatus ? 'userNavColor--text' : ''"
                >{{ LoginedUserName }}</span
              >
              <span v-else>
                <div>Kunden für Raumvermietung</div>
                <div>Meine Acht Töne</div></span
              >
            </v-btn>
          </div>
        </v-toolbar-items>
        <v-toolbar-items class="hidden-md-and-up">
          <v-app-bar-nav-icon
            variant="text"
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
        </v-toolbar-items>
      </v-toolbar>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <div
        :style="{
          position: 'fixed',
          top: scrollTop + 'px',
          width: '100%',
          zIndex: '1000',
        }"
      >
        <v-list nav dense>
          <v-list-item-group v-model="group">
            <v-list-item>
              <v-btn
                color="#37b038"
                text
                plain
                class="text-caption text-capitalize text-center"
                @click="
                  LoginedStatus
                    ? GoHomePage()
                    : openLoginDialogorGoUserBackend()
                "
                ><v-icon>mdi-account-outline</v-icon
                ><span
                  v-if="LoginedStatus"
                  :class="LoginedStatus ? 'userNavColor--text' : ''"
                  >{{ LoginedUserName }}</span
                >
                <span v-else>
                  <div>Kunden für Raumvermietung</div>
                  <div>Meine Acht Töne</div></span
                ></v-btn
              >
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              v-for="(item, index) in header"
              :key="index"
              class="px-8"
            >
              <v-list-item-title
                @click="redirectToWebsite(item.link)"
                class="achtgreen--text"
                >{{ item.title }}</v-list-item-title
              >
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => {
    return {
      drawer: false,
      group: null,
      scrollTop: 0,
      header: [
        { title: "Musik", link: "https://acht-toene.com/Musikzentrum.html" },
        { title: "Kunst", link: "https://acht-toene.com/Kunstzentrum.html" },
        { title: "Sprache", link: "https://acht-toene.com/Sprachzentrum.html" },
        { title: "Kultur", link: "https://acht-toene.com/Kulturzentrum.html" },
        { title: "Event", link: "https://acht-toene.com/Event.html" },
        { title: "Raumvermietung", link: "https://room.acht-toene.com/" },
      ],
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  mounted() {
    this.$store.dispatch("CheckCookieShowUserName");
    window.addEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapGetters(["LoginedStatus", "LoginedUserName", "CeckRouterPath"]),
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollTop = window.scrollY;
    },
    openLoginDialogorGoUserBackend() {
      this.$store.commit("ActivateLoginDialog");
    },
    redirectToWebsite(url) {
      window.location.href = url;
    },
    GoHomePage() {
      this.$router.push("/userDashboard/calendars");
    },
  },
};
</script>
