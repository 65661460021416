<template>
  <v-card>
    <v-data-table
      :headers="Headers"
      :items="Events"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :search="search"
      item-key="prIDX"
      show-expand
      class="elevation-1 text-center"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" v-if="item.Refund == 0">
          <v-row class="pt-4">
            <v-col cols="12">
              <span>Rückerstattung beantragen</span>
            </v-col>
          </v-row>
          <v-row class="pb-4 pl-6">
            <v-col cols="12" xs="12" sm="8">
              <v-form ref="RefundForm">
                <v-text-field
                  label="Grund für die Erstattung"
                  hint="Bitte geben Sie den Grund für Ihren Erstattungsantrag an."
                  persistent-hint
                  :rules="reasonRule"
                  v-model="descriptionforrefund"
                ></v-text-field>
              </v-form>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="4"
              class="d-flex align-center justify-center"
            >
              <v-btn class="red white--text" @click="RefundApplication(item)"
                >Erstattungen</v-btn
              >
            </v-col>
          </v-row>
        </td>
        <td :colspan="headers.length" v-if="item.Refund == 1">
          <v-row class="pt-4">
            <v-col cols="12">
              <span>Erstattung Time: {{ item.Applyrefund }}</span>
            </v-col>
          </v-row>
          <v-row class="pb-4 pl-6">
            <v-col cols="12" xs="12" sm="8">
              <span
                >Sie haben erfolgreich einen Erstattungsantrag gestellt. Bitte
                warten Sie geduldig, bis der Administrator den Antrag überprüft
                hat. Wenn die Überprüfung erfolgreich war, erhalten Sie Ihre
                Rückerstattung innerhalb von fünf Arbeitstagen. Vielen Dank für
                Ihre Unterstützung!</span
              >
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="8"
              class="d-flex align-center justify-center"
            >
              <v-btn
                class="achtgreen white--text"
                @click="CancelRefundApplication(item)"
                >Rücknahmeantrag</v-btn
              >
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-slot:[`item.StatusFormat`]="{ item }">
        <v-chip :color="getColor(item.Refund)" dark>
          {{ item.StatusFormat }}
        </v-chip>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" persistent max-width="330">
      <v-card>
        <v-card-title class="fontFamlily">
          Bestätigung der Anmeldung
        </v-card-title>
        <v-card-text class="fontFamlily"
          >Sind Sie sicher, dass Sie einen Erstattungsantrag stellen
          wollen?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="achtgreen white--text text-capitalize"
            @click="cancelconfirmed"
          >
            Stornieren
          </v-btn>
          <v-btn color="red white--text text-capitalize" @click="confirmed">
            Bestätigen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog></v-card
  >
</template>
<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      singleExpand: false,
      expanded: [],
      dialog: false,
      descriptionforrefund: "",
      search: "",
      Headers: [
        {
          text: "Name des Zimmers",
          align: "start",
          sortable: false,
          value: "Title",
        },
        { text: "Datum", value: "Date" },
        { text: "Zeitspanne", value: "Period" },
        // { text: "Dauer(Stunde)", value: "DurationFormat" },
        { text: "Betrag(€)", value: "Paid_price" },
        { text: "Status", value: "StatusFormat" },
      ],
      Events: [],
      StatusCheck: { 0: "Ready", 1: "Review", 2: "Refunded" },
      executor: null,
      reasonRule: [(v) => !!v || "Password is required"],
    };
  },
  mounted() {
    this.initDataTable();
  },
  methods: {
    initDataTable() {
      this.$axios
        .get("/my2/initUserEvents")
        .then((res) => {
          if (res.data.status !== 0) {
            this.$store.dispatch(
              "ActivateSnack",
              "Calendar Initialization Failed!"
            );
          }
          let temp_for_check = res.data.events;
          let temp = temp_for_check.filter((item) => item.Refund != 2);
          temp = temp.map((item) => {
            item.Date = dayjs(item.Book).add(0, "minute").format("YYYY-MM-DD");
            let startString = dayjs(item.Book).format("HH:mm");
            let endString = dayjs(item.Book)
              .add(item.Duration, "minute")
              .format("HH:mm");
            item.Period = `${startString} - ${endString}`;
            item.StatusFormat = this.StatusCheck[item.Refund];

            item.DurationFormat = item.Duration / 60;
            return item;
          });
          temp = temp.sort(
            (a, b) => dayjs(a.Book).valueOf() - dayjs(b.Book).valueOf()
          );
          temp = temp.filter(
            (item) => dayjs(item.Book).valueOf() - dayjs().valueOf() > 0
          );
          this.Events = temp;
        })
        .catch(() => {});
    },
    createExposedPromise() {
      let resolve, reject;
      const promise = new Promise((rs, rj) => {
        resolve = rs;
        reject = rj;
      });
      return {
        promise,
        executor: {
          resolve,
          reject,
        },
      };
    },
    confirmed() {
      this.executor.resolve();
      this.dialog = false;
    },
    cancelconfirmed() {
      this.executor.reject();
      this.dialog = false;
    },
    async RefundApplication(item) {
      let checkForm = this.$refs.RefundForm.validate();
      if (!checkForm) return;
      let promiseObj = this.createExposedPromise();
      let p = promiseObj.promise;
      this.executor = promiseObj.executor;
      try {
        this.dialog = true;
        await p;
      } catch {
        return;
      }

      this.$axios
        .post("/my2/eventRefundRequest", {
          prIDX: item.prIDX,
          Description: this.descriptionforrefund,
        })
        .then((res) => {
          if (res.data.status !== 0) {
            this.$store.dispatch(
              "ActivateSnack",
              "Application failed, please try again later!"
            );
          }
          this.$store.dispatch(
            "ActivateSnack",
            "Refund request has been sent!"
          );
          this.initDataTable();
        })
        .catch(() => {});
    },
    CancelRefundApplication(item) {
      this.$axios
        .post("/my2/cancelEventRefundRequest", { prIDX: item.prIDX })
        .then((res) => {
          if (res.data.status !== 0) {
            this.$store.dispatch(
              "ActivateSnack",
              "Application failed, please try again later!"
            );
          }
          this.$store.dispatch(
            "ActivateSnack",
            "Cancellation refund successful!"
          );
          this.initDataTable();
        })
        .catch(() => {});
    },
    getColor(Status) {
      if (Status == 0) return "#92d050";
      else if (Status == 1) return "orange";
      else return "red";
    },
  },
};
</script>
