import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import axios from 'axios'
import router from './router'
import store from './store/store'
import Cookies from "js-cookie";
//import Bus from './store/bus'
//Vue.prototype.$bus = Bus;
Vue.prototype.$axios = axios
// axios.defaults.baseURL = 'http://127.0.0.1:3008/'
axios.defaults.baseURL = 'https://room.acht-toene.com/'
axios.interceptors.request.use(config => {
  config.headers.Authorization = Cookies.get('token')
  return config
})
axios.interceptors.response.use(res => {
  return res
}, err => {
  if (err.response.status == 403) {
    router.push('/')
    store.commit("ActivateLoginDialog")
    return
  }
})
Vue.config.productionTip = false

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'md'
})


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
