<template>
  <v-card class="px-4 mt-9">
    <v-row>
      <v-col cols="12" class="titleFont"
        ><v-card-title>Raumnutzungsbedingungen:</v-card-title></v-col
      >
      <v-col cols="12" class="pt-0">
        <v-card-text class="textFont pt-0">
          Der Raum muss sauber gehalten werden. Nach dem Gebrauch muss es wieder
          in seinen ursprünglichen Zustand versetzt werden. Beim Üben Ihres
          Instruments sollten keine Fenster geöffnet sein. Parkverbot im Hintenhof.
        </v-card-text>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="titleFont"
        ><v-card-title class="font-weight-bold"
          >Stornobestimmungen:</v-card-title
        ></v-col
      >
      <v-col cols="12" class="pt-0">
        <v-card-text class="textFont pt-0">
          Vermieter haben immer die Möglichkeit, eine Buchung kostenfrei zu
          stornieren. Der Mieter wird dann benachrichtigt und die Zahlung wird
          zu 100% erstattet.
        </v-card-text>
        <v-card-text class="textFont pt-0">
          Mieter können eine Buchung dieses Raumes bis zu 72 Stunden vor
          Buchungsbeginn kostenfrei stornieren, sofern die Buchung mindestens 72
          Stunden vor Buchungsbeginn erfolgt ist. Die Zahlung wird dann zu 100%
          erstattet. Bis 72 Stunden vor Buchungsbeginn kann der Mieter nicht
          mehr kostenfrei stornieren. Nachfolgend sind die Stornobestimmungen
          für diesen Raum noch einmal zusammengefasst:
        </v-card-text>
        <v-card-text class="textFont pt-4">
          -<span class="font-weight-bold">Keine Rückerstattung</span> im Falle
          einer Stornierung, wenn Mieter seine Buchung dieses Raumes 24 (oder
          weniger) Stunden vor Buchungsbeginn storniert.
        </v-card-text>
        <v-card-text class="textFont pt-4">
          -<span class="font-weight-bold">Eine Rückerstattung i.H.v. 50%</span>,
          wenn Mieter seine Buchung dieses Raumes 48 (und bis zu 24) Stunden vor
          Buchungsbeginn storniert.
        </v-card-text>
        <v-card-text class="textFont pt-4">
          -<span class="font-weight-bold">Eine Rückerstattung i.H.v. 100%</span
          >, wenn Mieter seine Buchung dieses Raumes 72 (und bis zu 48) Stunden
          vor Buchungsbeginn storniert.
        </v-card-text>
        <v-card-text class="textFont pt-4">
          -Mieter kann seine Buchung dieses Raumes ab 72 Stunden vor
          Buchungsbeginn kostenfrei stornieren.
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

