import Vue from "vue";
import Router from "vue-router";
//import Cookies from "js-cookie";
import Raum from "./components/Pages/RaumView/RaumView.vue";
import Home from "./components/Pages/HomeView/HomeView.vue";
import ActivateUser from "./components/Pages/ActivateUser/ActivateUser.vue";
import ServerMaintance from "./components/Pages/ServerMaintance/ServerMaintance.vue";
import PurchaseView from "./components/Pages/PurchaseView/PurchaseView.vue";
import UserBackend from "./components/Pages/UserBackend/UserBackend.vue";
import CalenderView from "./components/Pages/UserBackend/CalenderView.vue";
import ManagementView from "./components/Pages/UserBackend/ManagementView.vue";
Vue.use(Router);
import Cookies from "js-cookie";
import store from "./store/store";
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Home,
      name: "Home",
      children: [
        // { path: '/AddFloorPlan', component: AddFloorPlan },
      ],
    },
    {
      path: "/room/:id",
      component: Raum,
      name: "Raum",
    },
    {
      path: "/activateAccount/:token",
      component: ActivateUser,
      name: "ActivateUser",
    },
    {
      path: "/maintance",
      component: ServerMaintance,
      name: "Maintance",
    },
    {
      path: "/purchase/:purchaseToken",
      component: PurchaseView,
      name: "Purchase",
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "/userDashboard",
      component: UserBackend,
      name: "UserBackend",
      meta: {
        requireAuth: true,
      },
      children: [
        { path: "/userDashboard/calendars", component: CalenderView },
        { path: "/userDashboard/management", component: ManagementView },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  store.commit("CheckRouterPathNavBar", to.path);
  if (!to.meta.requireAuth) return next();

  const tokenStr = Cookies.get("token");

  if (!tokenStr) {
    next("/");
    store.commit("ActivateLoginDialog");
    store.dispatch("ActivateSnack", "Please Login First!");
    return;
  }
  if (from.path == "/purchase/:purchaseToken")
    from.path = "/userDashboard/calendars";
  window.scrollTo(0, 0);
  next();
});

export default router;
