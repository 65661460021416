import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // Snack bar
        SnackShow: false,
        SnackText: '',
        //Login 
        LoginDialog: false,
        //Cookie
        LoginedStatus: false,
        LoginedUserName: '',
        //FirstSection
        FirstSectionTitle: '',
        FirstSectionParagraphs: [],
        // Equipment
        EquipAllowPerson: null,
        EquipArea: null,
        Equipment: [],
        //Nav Bar RouterPath
        CeckRouterPath: false,
        //Userbackend
        UserBanner:false,
        UserRouterCalendar:false,
        UserRouterManagement:false,
        //Purchase Form Check
        PurchaseCheck:false

    },
    // 读取数据
    getters: {
        SnackShow: state => state.SnackShow,
        SnackText: state => state.SnackText,
        LoginDialog: state => state.LoginDialog,
        LoginedStatus: state => state.LoginedStatus,
        LoginedUserName: state => state.LoginedUserName,
        FirstSectionTitle: state => state.FirstSectionTitle,
        FirstSectionParagraphs: state => state.FirstSectionParagraphs,
        EquipAllowPerson: state => state.EquipAllowPerson,
        EquipArea: state => state.EquipArea,
        Equipment: state => state.Equipment,
        CeckRouterPath: state => state.CeckRouterPath,
        UserBanner: state => state.UserBanner,
        UserRouterCalendar: state => state.UserRouterCalendar,
        UserRouterManagement: state => state.UserRouterManagement,
        PurchaseCheck: state => state.PurchaseCheck,
    },

    actions: {
        // Activate Snack component
        'ActivateSnack'({ commit }, message = '') {
            if (!message) return
            commit('SnackShow', message);
        },
        //Show login status
        'CheckCookieShowUserName'({ commit }) {
            let info = Cookies.get('decoded')
            if (info) {
                info = JSON.parse(info)
                commit('ChangeLoginStatusAndNameToTrue', info.Fullname);
            } else {
                commit('ChangeLoginStatusAndNameToFalse');
            }
        },
    },

    mutations: {
        //Snack
        'SnackShow'(state, message) {
            state.SnackShow = true;
            state.SnackText = message;
            setTimeout(() => {
                state.SnackShow = false;
            }, 4000)
        },
        //LoginDialog
        'ActivateLoginDialog'(state) {
            state.LoginDialog = true;
        },
        'InActivateLoginDialog'(state) {
            state.LoginDialog = false
        },
        //UserNameBy Cookie
        'ChangeLoginStatusAndNameToTrue'(state, name) {
            state.LoginedStatus = true
            state.LoginedUserName = name
        },
        'ChangeLoginStatusAndNameToFalse'(state) {
            state.LoginedStatus = false
            state.LoginedUserName = ''
        },
        // show FIrstsection
        'ShowFirstSection'(state, data) {
            state.FirstSectionTitle = data.Title;
            if (data.Description) {
                state.FirstSectionParagraphs = JSON.parse(data.Description);
            }
        },
        // show equipments
        'ShowEquipment'(state, data) {
            state.EquipAllowPerson = data.Allowpersons;
            state.EquipArea = data.Area;
            state.Equipment = JSON.parse(data.Equipment)
        },
        // Router Nav bar show       
        "CheckRouterPathNavBar"(state, path) {
            if (path == '/') {
                state.CeckRouterPath = true
            } else {
                state.CeckRouterPath = false
            }
            let WhiteListForUserBanner = ["/userDashboard","/userDashboard/calendars","/userDashboard/management"]
            if(WhiteListForUserBanner.includes(path)){
                state.UserBanner = true
            }else{
                state.UserBanner = false
            }
            if(path == '/userDashboard/calendars'){
                state.UserRouterCalendar = true
            }else{
                state.UserRouterCalendar = false
            }
            if(path == '/userDashboard/management'){
                state.UserRouterManagement = true
            }else{
                state.UserRouterManagement = false
            }
        },
        "PurchaseCheckCommit"(state, val){
            state.PurchaseCheck = val
        }
    }
})