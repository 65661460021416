<template>
  <div style="min-height: 300px" class="px-2">
    <v-row>
      <v-col cols="12" sm="12" md="3" class="py-4">
        <v-card height="300" class="overflow-hidden">
          <v-row
            ><v-col cols="12" class="mt-6 UserBanner mainBg py-0">
              <v-card-text
                class="fontFamlily fontActive--text text-start navBar px-8"
                >{{ Username }}</v-card-text
              ></v-col
            >
            <v-col cols="12" class="pt-0 UserBanner py-0">
              <v-hover v-model="hover"
                ><router-link
                  to="/userDashboard/calendars"
                  class="text-decoration-none fontActive--text"
                  @click="setRouterValue"
                  ><v-card-text class="navBar d-flex justify-start flex-row"
                    ><v-icon
                      class="px-4"
                      :color="hover || UserRouterCalendar ? 'achtgreen' : ''"
                      >mdi-calendar</v-icon
                    ><span
                      :class="
                        hover || UserRouterCalendar ? 'achtgreen--text' : ''
                      "
                      >Kalender</span
                    >
                  </v-card-text>
                </router-link></v-hover
              >

              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="pt-0 UserBanner py-0">
              <v-hover v-model="hover1"
                ><router-link
                  to="/userDashboard/management"
                  class="text-decoration-none fontActive--text"
                  @click="setRouterValue"
                  ><v-card-text class="navBar d-flex justify-start flex-row"
                    ><v-icon
                      class="px-4"
                      :color="hover1 || UserRouterManagement ? 'achtgreen' : ''"
                      >mdi-contrast</v-icon
                    ><span
                      :class="
                        hover1 || UserRouterManagement ? 'achtgreen--text' : ''
                      "
                      >Verwaltung
                    </span>
                  </v-card-text>
                </router-link></v-hover
              >
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="pt-0 UserBanner py-0">
              <v-hover v-model="hover2"
                ><v-card-text
                  class="navBar d-flex justify-start flex-row"
                  @click="Logout"
                  ><v-icon class="px-4" :color="hover2 ? 'red' : ''"
                    >mdi-logout-variant</v-icon
                  ><span :class="hover2 ? 'red--text' : ''">Abmelden </span>
                </v-card-text>
              </v-hover>
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="9" class="py-23">
        <router-view></router-view>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Cookie from "js-cookie";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
export default {
  data() {
    return {
      hover: false,
      hover1: false,
      hover2: false,
      routerPath: "",
      Username: "",
    };
  },
  computed: {
    ...mapGetters(["UserRouterCalendar", "UserRouterManagement"]),
  },
  mounted() {
    this.setRouterValue();
    let decoded = JSON.parse(Cookie.get("decoded"));
    this.Username = decoded.Username;
  },
  methods: {
    setRouterValue() {
      this.routerPath = this.$router.currentRoute.path;
    },
    Logout() {
      Cookie.set("token", "", {
        expires: dayjs().subtract(1, "hours").valueOf(),
        domain: '.acht-toene.com' 
      });
      Cookie.set("decoded", "", {
        expires: dayjs().subtract(1, "hours").valueOf(),
        domain: '.acht-toene.com'
      });
      Cookie.remove("token");
      Cookie.remove("decoded");
      this.$store.commit("ChangeLoginStatusAndNameToFalse");
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
.v-main {
  padding-top: 10px !important;
}
.navBar {
  font-size: 16px;
}
</style>