<template>
  <div style="padding-top:100px;">
    <BookingInfoSection :BookingInfo="Bookinfomation" />
    <UserInfoSection @callbackUserInfo="callbackUserInfo" class="mt-8"/>
    <PurchaseMethod :BookingInfo="Bookinfomation" :UserInfo="Userinfomation" :token="purchaseToken" class="mt-8"/>
  </div>
</template>
<script>
import BookingInfoSection from "./BookinfoSection.vue";
import UserInfoSection from "./UserInfoSection.vue";
import PurchaseMethod from "./PurchaseMethod.vue";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
export default {
  components: { BookingInfoSection ,UserInfoSection,PurchaseMethod},
  data() {
    return {
      Bookinfomation: {},
      Userinfomation: {},
      purchaseToken:''
    };
  },
  mounted() {
    this.decodedToken();
  },
  methods: {
    callbackUserInfo(val){
        this.Userinfomation = val
    },
    decodedToken() {
      this.purchaseToken = this.$router.currentRoute.params.purchaseToken;
      let purchaseTokenDecoded = jwt_decode(this.purchaseToken);
      purchaseTokenDecoded.tokeninfo.TimeStamp =
        dayjs(`2020/01/01 ${purchaseTokenDecoded.tokeninfo.timeS}`).format(
          "HH:mm:ss"
        ) +
        " - " +
        dayjs(`2020/01/01 ${purchaseTokenDecoded.tokeninfo.timeS}`)
          .add(parseInt(purchaseTokenDecoded.tokeninfo.Hours, 10), "hours")
          .format("HH:mm:ss");
      this.Bookinfomation = purchaseTokenDecoded.tokeninfo;
    },
  },
};
</script>