<template>
  <v-form ref="LoginForm">
    <v-card flat>
      <v-card-text class="pb-0"
        ><span class="title titleColor--text">E-Mail Address</span>
        <v-text-field
          outlined
          dense
          clearable
          color="achtgreen"
          :hide-details="openRuleMessage"
          v-model="username"
          :rules="usernameRule"
        ></v-text-field
      ></v-card-text>
      <v-card-text
        ><span class="title titleColor--text">Password</span>
        <v-text-field
          outlined
          dense
          clearable
          color="achtgreen"
          :hide-details="openRuleMessage"
          :type="ShowPassword ? 'text' : 'password'"
          v-model="password"
          :rules="passwordRule"
        >
          <template v-slot:append>
            <v-btn
              icon
              @click="ShowPasswordFunc"
              :style="{ marginTop: '-6px' }"
            >
              <v-icon>{{ ShowPassword ? "mdi-eye-off" : "mdi-eye" }}</v-icon>
            </v-btn>
          </template></v-text-field
        ></v-card-text
      >
      <v-card-text class="py-0"
        ><a href="https://acht-toene.com/ResetMail"
          ><span class="title achtgreen--text">Passwort vergessen?</span></a
        >
      </v-card-text>
      <v-card-actions class="px-4 pb-4">
        <v-btn
          color="achtgreen white--text fontFamlily text-capitalize btnWidth"
          @click="login"
        >
          Anmelden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      ShowPassword: false,
      username: "",
      usernameRule: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      password: "",
      passwordRule: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 6 || "Password must be more than 6 characters",
      ],
      openRuleMessage: true,
    };
  },
  methods: {
    ShowPasswordFunc() {
      this.ShowPassword = !this.ShowPassword;
    },
    login() {
      let checkForm = this.$refs.LoginForm.validate();
      if (!checkForm) {
        this.openRuleMessage = false;
        setTimeout(() => {
          this.openRuleMessage = true;
        }, 5000);
        return;
      }
      this.$axios
        .post("/api2/login", {
          username: this.username,
          password: this.password,
        })
        .then((res) => {
          if (res.data.status !== 0) {
            if (res.data.message == "Login Account Not Exist") {
              this.$store.dispatch(
                "ActivateSnack",
                "Login Failed! Your Account Is Not Exist!"
              );
            } else if (res.data.message == "Duplicate Login Account") {
              this.$store.dispatch(
                "ActivateSnack",
                "Login Failed! Please Contact Admin!"
              );
            } else if (res.data.message == "Password Not Correct") {
              console.log("here");
              this.$store.dispatch(
                "ActivateSnack",
                "Login Failed! Please Check Your Account or Password!"
              );
            } else if (res.data.message == "Inactive User") {
              this.$store.dispatch(
                "ActivateSnack",
                "Login Failed! Please Check Your Email and Activate Your Account!"
              );
            } else {
              this.$store.dispatch("ActivateSnack", "Login Failed!");
            }
            throw new Error("err");
          }
          const token = res.data.token;
          const twoHours = new Date(new Date().getTime() + 120 * 60 * 1000);
          Cookies.set("token", token, { expires: twoHours });
          let decoded = jwt_decode(token);
          Cookies.set("decoded", JSON.stringify(decoded), {
            expires: twoHours,
          });
          this.$store.dispatch("CheckCookieShowUserName");
          this.$store.commit("InActivateLoginDialog");
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.title {
  font-family: "Rubik" !important;
  font-size: 14px !important;
}
.btnWidth {
  width: 100%;
}
</style>
