<template>
  <div style="padding-top:100px;">
    <v-card class="py-9 px-4">
      <v-row>
        <v-col cols="12" md="8">
          <PictureView/>
        </v-col>
        <v-col cols="12" md="4">
          <v-card class="mainBg" elevation="0">
            <v-card-title class="titleFont text-break">{{
              FirstSectionTitle
            }}</v-card-title>
            <v-card-text
              class="textFont"
              v-for="(item, index) in FirstSectionParagraphs"
              :key="index"
              >{{ item.text }}</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <BookRam />
    <EquipRaum />
    <RulesRaum />
  </div>
</template>

<script>
import PictureView from "./PictureShow.vue";
import EquipRaum from "./EquipRaum.vue";
import RulesRaum from "./RulesRaum.vue";
import BookRam from "./BookRam.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    PictureView,
    EquipRaum,
    RulesRaum,
    BookRam,
  },
  computed: {
    ...mapGetters(["FirstSectionTitle", "FirstSectionParagraphs"]),
  },
  methods: {},
};
</script>

