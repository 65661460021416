<template>
  <div class="box py-16 d-flex align-center justify-center">
    <div class="text-center text-h4"  v-if="!success && !faild">
      <p class="fontFamlily">
        Your account is being activated, one moment please!
      </p>
      <v-progress-circular
        :size="70"
        :width="7"
        color="achtgreen"
        indeterminate
      ></v-progress-circular>
    </div>
    <div class="text-center text-h4" v-if="success">
      <p class="fontFamlily">
        Your account has been successfully activated! Cheers to you!
      </p>
      <v-icon class="achtgreen--text" size="48"
        >mdi-check-circle-outline</v-icon
      >
    </div>
    <div class="text-center text-h4" v-if="faild">
      <p class="fontFamlily">
        Activation failed, please try again later or contact the administrator!
      </p>
      <v-icon class="userNavColor--text" size="48"
        >mdi-close-circle-outline</v-icon
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      success: false,
      faild: false,
    };
  },
  mounted() {
    this.activateAccount();
  },
  methods: {
    activateAccount() {
      this.$axios
        .post("/api2/activateAccount", {
          token: this.$router.currentRoute.params.token,
        })
        .then((res) => {
          if (res.data.status !== 0) {
            throw new Error("err");
          }
          setTimeout(() => {
            this.success = true;
          }, 3000);
        })
        .catch(() => {
          setTimeout(() => {
            this.faild = true;
          }, 3000);
        });
    },
  },
};
</script>
<style scoped>
.box {
  height: 500px;
}
</style>