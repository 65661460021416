<template>
  <div>
    <v-card elevation="0">
      <v-row class="wrapper pa-4">
        <v-col cols="12" md="6">
          <v-card-title class="titleFont pl-0 pb-9" style="white-space: nowrap;"
            ><span class="achtgreen mr-4 hidden-xs-only"
              >&nbsp;&nbsp;&nbsp;</span
            ><span>KONTAKTIEREN SIE UNS</span>
          </v-card-title>
          <v-row>
            <v-col cols="6" class="textFont">Ort:</v-col>
            <v-col cols="6" class="textFont font-weight-bold"
              >Acht Töne Musik- und Kunstschule Große Seestr. 32-34, Hinterhaus,
              2.Stock, 60486 Frankfurt am Main</v-col
            >
          </v-row>
          <v-row>
            <v-col cols="6" class="textFont">Telefon:</v-col>
            <v-col cols="6" class="textFont font-weight-bold"
              >+49 - 176 - 57876888</v-col
            >
          </v-row>
          <v-row>
            <v-col cols="6" class="textFont">E-Mail:</v-col>
            <v-col cols="6" class="textFont font-weight-bold"
              >info@acht-toene.com</v-col
            >
          </v-row>
        </v-col>
        <v-col cols="12" md="6"
          ><iframe
            style="height: 300px; width: 100%"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            allowfullscreen=""
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJNTGfoUIJvUcRZhnWPjkWSE0&amp;key=AIzaSyAkufw8-rhsdffsSCvQLlc4Tmh4bdNsIo4"
          ></iframe
        ></v-col>
      </v-row>
    </v-card>
    <v-card elevation="0" class="achtdeepgreen rounded-0">
      <v-row class="wrapper pa-6 d-flex justify-space-between">
        <v-col
          cols="12"
          md="6"
          lg="2"
          v-for="(item, index) in footerNav"
          :key="index"
          class="text-center white--text footerFont d-inline"
          ><a :href="item.link" class="a-style">{{ item.name }}</a></v-col
        >
      </v-row>
    </v-card>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      footerNav: [
        { name: "Kontakt", link: "https://acht-toene.com/Kontakt.html" },
        { name: "AGB", link: "https://acht-toene.com/AGB.html" },
        { name: "Impressum", link: "https://acht-toene.com/Impressum.html" },
        { name: "Datenschutz", link: "https://acht-toene.com/Datenschutz.html" },
        { name: "Über Uns", link: "https://acht-toene.com/ueber-uns.html" },
      ],
    };
  },
  created() {},
  methods: {
    setYear: function () {
      this.currentYear = new Date().getFullYear();
    },
  },
};
</script>
<style scope>
.wrapper {
  max-width: 1260px;
  height: 100%;
}
.footerFont {
  font-family: "Rubik", cursive;
  font-size: 1.6rem;
  font-weight: 700;
}
.a-style {
  text-decoration: none;
  color: #fff !important;
}
</style>
