<template>
  <div class="box" style="padding-top:164px;">
   
    <div class="text-center text-h4">
      <p class="fontFamlily">
        The server is under maintenance...
      </p>
      <v-icon class="achtgreen--text" size="48"
        >mdi-emoticon-sad-outline</v-icon
      >
    </div>
   
  </div>
</template>
<script>
export default {
  data() {
    return {
 
    };
  },
  mounted() {
    
  },
  methods: {

  },
};
</script>
<style scoped>
.box {
  height: 400px;
}
</style>