<template>
  <div>
    <v-tabs v-model="tab" color="achtgreen">
      <v-tab>Buchung</v-tab>
      <v-tab>Abgeschlossen</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <ReadyView />
      </v-tab-item>
      <v-tab-item>
        <RefundView />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import ReadyView from "./ReadyView.vue";
import RefundView from "./RefundView.vue";
export default {
  components: { ReadyView, RefundView },
  data() {
    return {
      tab: null,
    };
  },
};
</script>