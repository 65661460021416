<template>
  <v-snackbar :value="SnackShow">
    {{ SnackText }}
  </v-snackbar>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["SnackShow", "SnackText"]),
  },
  methods: {},
};
</script>