<template>
  <div>
    <v-carousel
      cycle
      hide-delimiter-background
      show-arrows-on-hover
      class="hidden-xs-only"
      height="400"
    >
      <v-carousel-item v-for="(img, i) in Pictures" :key="i">
        <v-img
          :src="img.picture"
          width="700"
          height="400"
          class="mx-auto"
        ></v-img>
      </v-carousel-item>
    </v-carousel>
    <v-carousel
      cycle
      hide-delimiter-background
      show-arrows-on-hover
      height="250"
      class="hidden-sm-and-up"
    >
      <v-carousel-item v-for="(img, i) in Pictures" :key="i">
        <v-img
          :src="img.picture"
          width="400"
          height="300"
          class="mx-auto"
        ></v-img>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  data: () => ({
    RaumIDX: null,
    Pictures: [],
    // for server faild if has no picture furture
    // imgs: [
    //   { src: require("../../../assets/picture/Raum1/1.jpeg") },
    //   { src: require("../../../assets/picture/Raum1/2.jpg") },
    // ],
  }),
  mounted() {
    this.RaumIDX = this.$router.currentRoute.params.id;
    this.initPictureSection();
  },
  methods: {
    initPictureSection() {
      this.$axios
        .get("/api2/initPictureSection", {
          params: { RaumIDX: this.RaumIDX },
        })
        .then((res) => {
          if (res.data.status !== 0) {
            //this.$router.push("/maintance");
          }
          let temp = res.data.data;
          temp = temp.map((item) => {
            item.Picture = JSON.parse(item.Picture);
            return item;
          });
          this.Pictures = temp[0].Picture;
          this.$store.commit("ShowFirstSection", temp[0]);
          this.$store.commit("ShowEquipment", temp[0]);
        })
        .catch(() => {
          //this.$router.push("/maintance");
        });
    },
  },
};
</script>
